import React from 'react';
import { Box, BoxProps, Container, ContainerProps } from '@mui/material';
import theme from '../../../theme';

interface PageSectionWithImageProps {
  bgcolor?: BoxProps['bgcolor'];
  color?: BoxProps['color'];
  sx?: BoxProps['sx'];
  maxWidth?: ContainerProps['maxWidth'];
  image: React.ReactNode;
  side: 'left' | 'right';
  py?: BoxProps['py'];
}

export default (props: React.PropsWithChildren<PageSectionWithImageProps>) => (
  <Box component='section' bgcolor={props.bgcolor} color={props.color} sx={props.sx}>
    <Container maxWidth={props.maxWidth}>
      <Box py={props.py ?? { xs: 3, sm: 6 }} position='relative'>
        {props.children}
        <Box
          display={{ xs: 'none', sm: 'initial' }}
          position='absolute'
          top={0}
          {...(props.side === 'left' ? { right: `calc(100% + ${theme.spacing(6)})` } : {})}
          {...(props.side === 'right' ? { left: `calc(100% + ${theme.spacing(6)})` } : {})}
          height='100%'
          width='100%'
        >
          {props.image}
        </Box>
      </Box>
    </Container>
  </Box>
);
