import React from 'react';
import { graphql, PageProps } from 'gatsby';
import ArticleStyledHtml from '../components/ArticleStyledHtml';
import OtherPage from '../components/Pages/OtherPage';

export const pageQuery = graphql`
  query OtherMarkdownRemark($id: String!) {
    current: markdownRemark(childOtherMarkdownRemark: { id: { eq: $id } }) {
      id
      fields {
        slug
      }
      frontmatter {
        title
      }
      html
    }
  }
`;

export default ({ data: { current } }: PageProps<Queries.OtherMarkdownRemarkQuery>) => (
  <OtherPage title={current!.frontmatter!.title!} slug={'/' + current!.fields!.slug!}>
    <ArticleStyledHtml html={current!.html!} />
  </OtherPage>
);
