import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import PageSectionWithSideImage from '../Sections/PageSectionWithSideImage';
import StandardBreadcrumbs from '../StandardBreadcrumbs';
import StandardFrame from '../StandardFrame';

interface OtherPageProps {
  title: string;
  slug: string;
}

export default (props: React.PropsWithChildren<OtherPageProps>) => (
  <>
    <Helmet title={props.title} />
    <StandardFrame>
      <PageSectionWithSideImage
        bgcolor='tertiary.main'
        maxWidth='sm'
        side='left'
        image={
          <StaticImage
            style={{ height: '100%' }}
            layout='constrained'
            quality={95}
            src='../../images/side_doctor.jpg'
            alt=''
          />
        }
      >
        <StandardBreadcrumbs path={[{ title: props.title, link: props.slug }]} />
        {props.children}
      </PageSectionWithSideImage>
    </StandardFrame>
  </>
);
